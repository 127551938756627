import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdError } from "react-icons/md";
import HomeContent from "./pages/Home";
import NotFound from "./pages/NotFound";

// Add this new component using Tailwind
const LoadingScreen = () => (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    {/* Backdrop with subtle blur */}
    <div className="absolute inset-0 bg-slate-900/50 backdrop-blur-md"></div>
    
    {/* Modern glass container */}
    <div className="relative w-full max-w-lg mx-4">
      <div className="relative p-10 rounded-3xl bg-white/90 shadow-[0_8px_40px_-12px_rgba(0,0,0,0.3)] overflow-hidden">
        <div className="flex flex-col items-center gap-12 pt-6">
          {/* Enhanced creative loader */}
          <div className="relative w-32 h-32">
            {/* Multiple rotating gradient rings */}
            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 animate-[spin_3s_linear_infinite]"></div>
            <div className="absolute inset-[2px] rounded-full bg-white"></div>
            <div className="absolute inset-[4px] rounded-full bg-gradient-to-r from-purple-600 to-violet-600 animate-[spin_4s_linear_infinite_reverse]"></div>
            <div className="absolute inset-[6px] rounded-full bg-white"></div>
            <div className="absolute inset-[8px] rounded-full bg-gradient-to-r from-blue-600 to-purple-600 animate-[spin_5s_linear_infinite]"></div>
            
            {/* Inner content */}
            <div className="absolute inset-[10px] rounded-full bg-white flex items-center justify-center">
              {/* Pulsing center with multiple layers */}
              <div className="relative w-12 h-12">
                <div className="absolute inset-0 rounded-full bg-gradient-to-br from-blue-500 to-purple-600 animate-ping-slow opacity-70"></div>
                <div className="absolute inset-1 rounded-full bg-gradient-to-br from-purple-500 to-violet-600 animate-ping-slower opacity-80"></div>
                <div className="absolute inset-2 rounded-full bg-gradient-to-br from-blue-400 to-violet-500 animate-pulse-scale"></div>
              </div>
            </div>
            
            {/* Enhanced floating particles */}
            <div className="absolute -inset-10">
              {/* More particles with varied animations */}
              <div className="absolute top-1/2 left-0 w-3 h-3 bg-blue-400 rounded-full animate-ping-fast opacity-60"></div>
              <div className="absolute top-1/4 right-0 w-3 h-3 bg-purple-400 rounded-full animate-ping-fast delay-100 opacity-60"></div>
              <div className="absolute bottom-0 left-1/2 w-3 h-3 bg-violet-400 rounded-full animate-ping-fast delay-200 opacity-60"></div>
              <div className="absolute top-0 left-1/3 w-2 h-2 bg-blue-300 rounded-full animate-ping-fast delay-300 opacity-60"></div>
              <div className="absolute bottom-1/4 right-1/4 w-2 h-2 bg-purple-300 rounded-full animate-ping-fast delay-400 opacity-60"></div>
            </div>
          </div>

          {/* Enhanced text content */}
          <div className="text-center space-y-6">
            <h2 className="text-[28px] font-bold text-gray-800">
              Redirecting to Job Page
            </h2>
            <div className="flex flex-col items-center gap-4">
              <p className="text-[18px] text-gray-600">
                Please wait while we redirect you
              </p>
              {/* Smoother animated dots */}
              <div className="flex items-center gap-2">
                <div className="w-2.5 h-2.5 rounded-full bg-blue-600 animate-pulse-fast"></div>
                <div className="w-2.5 h-2.5 rounded-full bg-purple-600 animate-pulse-fast delay-100"></div>
                <div className="w-2.5 h-2.5 rounded-full bg-violet-600 animate-pulse-fast delay-200"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Enhanced background effects */}
        <div className="absolute inset-0 -z-10">
          <div className="absolute top-0 left-1/4 w-32 h-32 bg-blue-600/10 rounded-full mix-blend-multiply filter blur-xl animate-float"></div>
          <div className="absolute bottom-0 right-1/4 w-32 h-32 bg-purple-600/10 rounded-full mix-blend-multiply filter blur-xl animate-float-delayed"></div>
        </div>
      </div>

      {/* Outer glow effect */}
      <div className="absolute -inset-1 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-[20px] blur-2xl -z-10"></div>
    </div>
  </div>
);

// Add this new component using Tailwind
const ErrorScreen = ({ onClose }) => (
  // Lighter overlay
  <div 
    className="fixed inset-0 z-50 flex items-center justify-center bg-black/30"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
  >
    {/* Modal container */}
    <div className="w-full max-w-md p-6 mx-4">
      {/* Glass effect container with lighter background */}
      <div className="relative overflow-hidden rounded-2xl bg-white/80 backdrop-blur-md border border-rose-500/20 shadow-2xl">
        <div className="relative p-6 z-10">
          <div className="flex flex-col items-center gap-8">
            {/* Error icon */}
            <div className="relative">
              <div className="w-16 h-16 rounded-full bg-rose-500/10 flex items-center justify-center animate-bounce-gentle">
                <MdError className="w-10 h-10 text-rose-500" />
              </div>
              {/* Pulsing ring */}
              <div className="absolute inset-0 rounded-full border-2 border-rose-500/30 animate-ping"></div>
            </div>

            {/* Enhanced error message with more spacing */}
            <div className="text-center space-y-3">
              <h2 className="text-[24px] font-bold text-gray-800">
                Link Not Found
              </h2>
              <p className="text-gray-600 max-w-sm text-[20px] leading-relaxed">
                We couldn't locate the job posting you're looking for. It may have been removed or expired.
              </p>
              
              {/* Close button with more padding */}
              <button
                onClick={onClose}
                className="mt-16 px-6 py-3 rounded-xl bg-gradient-to-r from-rose-500 to-orange-500 text-white font-medium text-lg
                         transform transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-rose-500/30
                         focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
              >
                Return Home
              </button>
            </div>
          </div>

          {/* Subtle background elements */}
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <div className="absolute -top-4 -left-4 w-24 h-24 bg-rose-500/10 rounded-full blur-xl animate-float"></div>
            <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-orange-500/10 rounded-full blur-xl animate-float-delayed"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Middleware component to handle redirection logic
const Home = () => {
  const navigate = useNavigate();
  
  const getUniqueId = () => {
    // Get the raw URL before any React Router processing
    const rawUrl = window.location.toString();
    console.log('[Redirect] Raw URL:', rawUrl);

    try {
      // Method 1: Using URL constructor with toString()
      const urlObj = new URL(rawUrl);
      const qParam1 = urlObj.searchParams.get('q');
      
      // Method 2: Using URLSearchParams with location.search
      const searchParams = new URLSearchParams(window.location.search);
      const qParam2 = searchParams.get('q');

      // Method 3: Manual query string parsing
      const queryString = window.location.href.split('?')[1];
      const qParam3 = queryString ? new URLSearchParams(queryString).get('q') : null;
      
      console.log('[Redirect] URL parsing attempts:', {
        method1: qParam1,
        method2: qParam2,
        method3: qParam3,
        rawUrl,
        search: window.location.search,
        queryString
      });

      // Return the first successful result
      return qParam1 || qParam2 || qParam3 || null;
    } catch (error) {
      console.error('[Redirect] URL parsing error:', error);
      return null;
    }
  };

  const uniqueId = getUniqueId();
  
  // Initialize loading as true if uniqueId exists
  const [status, setStatus] = React.useState({
    loading: !!uniqueId,
    error: false
  });

  useEffect(() => {
    const handleRedirect = async () => {
      if (uniqueId) {
        try {
          const [response] = await Promise.all([
            fetch(`${process.env.REACT_APP_DEV_API}/v1/job/unique-id/${uniqueId}`),
            new Promise(resolve => setTimeout(resolve, 2000))
          ]);

          const data = await response.json();

          if (response.ok && data.data?.url) {
            window.location.href = data.data.url;
          } else {
            setStatus({
              loading: false,
              error: true
            });
          }
        } catch (error) {
          console.error('[Redirect] Fetch error:', error);
          setStatus({
            loading: false,
            error: true
          });
        }
      }
    };

    handleRedirect();
  }, [uniqueId]);

  // Handle error screen close
  const handleClose = () => {
    navigate('/');
  };

  if (uniqueId) {
    // Only show error OR loading, not both
    if (status.error) {
      return <ErrorScreen onClose={handleClose} />;
    } else if (status.loading) {
      return <LoadingScreen />;
    }
  }

  return <HomeContent />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
